import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, compact } from 'lodash';
import classnames from 'classnames';
/* Utils */
import {
  getCompareLink,
  getPricingReviewsLinks,
  getResearchLinks,
  getResearchTypeLinks,
  getSrpLink,
  highlightQuery,
} from 'site-modules/shared/utils/inventory/global-search-links';
import { fireSearchLinkTracking } from 'site-modules/shared/utils/inventory/global-search-tracking';
/* Constants */
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
/* Components */
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { GlobalSearchThumbnail } from 'site-modules/shared/components/inventory/global-search/global-search-thumbnail/global-search-thumbnail';
import { Link } from 'site-modules/shared/components/link/link';

export const GlobalSearchLinks = memo(({ query, fastMatcherData, isMobile }) => {
  const globalSearchLinks = useMemo(
    () => ({
      pricingReviewsLinks: {
        links: getPricingReviewsLinks({ fastMatcherData }),
        icon: 'icon-compare-arrows',
        label: 'New Pricing & Reviews',
        withThumbnail: true,
      },
      usedSrpLinks: {
        links: compact([
          getSrpLink({ fastMatcherData, types: [INVENTORY_TYPES_LOWERCASE.USED, INVENTORY_TYPES_LOWERCASE.CPO] }),
        ]),
        icon: 'icon-shopping-cart',
        label: 'Shopping',
      },
      newSrpLinks: {
        links: compact([getSrpLink({ fastMatcherData, types: [INVENTORY_TYPES_LOWERCASE.NEW] })]),
        icon: 'icon-shopping-cart',
        label: 'Shopping',
      },
      compareLinks: {
        links: compact([getCompareLink({ fastMatcherData })]),
        icon: 'icon-compare-arrows',
        label: 'Compare',
      },
      researchLinks: {
        links: getResearchLinks({ fastMatcherData }),
        icon: 'icon-list',
        label: 'Research',
      },
      researchTypeLinks: {
        links: getResearchTypeLinks({ fastMatcherData }),
        icon: 'icon-list',
        label: 'Research',
      },
    }),
    [fastMatcherData]
  );

  const handleClick = useCallback(
    ({ selectionType, title }) => () =>
      fireSearchLinkTracking({
        input: query,
        fastMatcherData,
        value: title,
        selectionType,
        mmtSearchId: fastMatcherData?.trace?.uuid,
      }),
    [query, fastMatcherData]
  );

  return (
    <ul className="list-unstyled mb-0">
      {map(
        globalSearchLinks,
        ({ links, icon, label, withThumbnail }) =>
          !isEmpty(links) &&
          links.map(({ title, url, makeSlug, modelSlug, year, selectionType }) => (
            <li key={`${title}-${url}`}>
              <Link
                to={url}
                className={classnames('text-decoration-none d-flex w-100 global-search-link py-0_75 rounded-8', {
                  'px-0_5': !isMobile,
                })}
                onClick={handleClick({ selectionType, title })}
              >
                {withThumbnail ? (
                  <GlobalSearchThumbnail make={makeSlug} model={modelSlug} year={year} />
                ) : (
                  <div className="gray-circle d-flex align-items-center justify-content-center align-self-center rounded-circle mr-1 bg-cool-gray-90 text-cool-gray-40">
                    <span className={classnames('icon medium', icon)} aria-hidden />
                  </div>
                )}
                <div>
                  <span className="font-weight-bold d-block">
                    <ContentFragment componentToUse="span">
                      {highlightQuery({ userInput: query, title })}
                    </ContentFragment>
                  </span>
                  <span className="medium text-cool-gray-40">{label}</span>
                </div>
              </Link>
            </li>
          ))
      )}
    </ul>
  );
});

GlobalSearchLinks.displayName = 'GlobalSearchLinks';

GlobalSearchLinks.propTypes = {
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  fastMatcherData: PropTypes.shape({
    delta: PropTypes.string,
    struct: PropTypes.shape({}),
  }).isRequired,
};

GlobalSearchLinks.defaultProps = {
  query: '',
  isMobile: false,
};
