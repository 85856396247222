import { get, flow, map, maxBy, minBy, values, head, merge } from 'lodash';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { EdmundsAPI } from 'client/data/api/api-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { getModelState } from 'client/data/luckdragon/model';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { getAverageValue } from 'site-modules/shared/utils/math-utils';
import { PageModel } from './page';
import { VisitorModel } from './visitor';
import { getRadiusByDmaRank } from './inventory';
import { fetchImages } from './profile/profile-image-helper';

const INVENTORY_AGGREGATE_API_PREFIX = '/inventory/v5/aggregate';
const takeFirstObjectValue = flow(
  values,
  head
);
const DEFAULT_SUBMODEL = 'default';

/**
 * Get finite value or null.
 * @param {object} data
 * @param {string} path - path in data object.
 * @returns {number|null}
 */
export const getFiniteValue = (data, path) => {
  const value = get(data, path);

  return Number.isFinite(value) ? value : null;
};

/**
 * Transforms ratings from map to array
 * @param dealerRatings
 * @return {array} - array of rating item ({ value: number, number: number })
 */
export const transformDealerRatings = dealerRatings =>
  Object.keys(dealerRatings).map(key => ({
    value: Number(key),
    number: dealerRatings[key],
  }));

/**
 * Gets min and max ratings
 * @param ratings
 * @return {{minRating: number, maxRating: number}}
 */
export function getMinMaxRatings(ratings) {
  const ratingsArr = transformDealerRatings(ratings);
  const ratingsAvailable = ratingsArr && ratingsArr.length;
  const minRating = ratingsAvailable ? minBy(ratingsArr, 'value').value : null;
  const maxRating = ratingsAvailable ? maxBy(ratingsArr, 'value').value : null;

  return { minRating, maxRating };
}

/**
 * Gets min and max prices of all given submodels data into simplified object
 * @param submodels
 * @return {Object}
 * example (using submodels with niceId):
 * getPriceRanges({
 *  sedan: { "min_prices.advertisedPrice": 10000, "max_prices.advertisedPrice": 20000 },
 *  coupe: { "min_prices.advertisedPrice": 10000, "max_prices.advertisedPrice": 20000 }
 * }) => {
 *  sedan: { min: 10000, max: 20000 },
 *  coupe: { min: 10000, max: 20000 }
 * }
 */
export function getPriceRanges(submodels) {
  const priceRanges = {};
  if (submodels) {
    Object.keys(submodels).forEach(key => {
      priceRanges[key] = {
        min: getFiniteValue(submodels[key], 'min_prices.advertisedPrice'),
        max: getFiniteValue(submodels[key], 'max_prices.advertisedPrice'),
      };
      return priceRanges[key];
    });
  }

  return priceRanges;
}

export const transformTrims = trims =>
  map(trims, (item, key) => ({
    name: key,
    inventoryCount: item.count,
    minPrice: getFiniteValue(item, 'min_prices.advertisedPrice'),
    maxPrice: getFiniteValue(item, 'max_prices.advertisedPrice'),
    minMileage: getFiniteValue(item, 'min_vehicleInfo.mileage'),
    maxMileage: getFiniteValue(item, 'max_vehicleInfo.mileage'),
  }));

/**
 * @param {Object} greatDeals
 * @return {Array} transformed and flattened great deals data
 */
export const transformGreatDeals = greatDeals =>
  map(greatDeals['vehicleInfo.styleInfo.model'], ({ count, ...data }, key) => {
    const makeObj = takeFirstObjectValue(data);
    const styleIds = takeFirstObjectValue(makeObj)['vehicleInfo.styleInfo.styleId'];

    return {
      count,
      model: key,
      make: Object.keys(makeObj)[0],
      styleId: Object.keys(styleIds)[0],
    };
  });

export function getUsedPriceRange(priceRange, pricesByTrim) {
  let minPrice;
  let maxPrice;
  if (!priceRange || !pricesByTrim) {
    return null;
  }
  const minPrices = pricesByTrim.map(item => item.minPrice).sort((a, b) => a - b);
  const maxPrices = pricesByTrim.map(item => item.maxPrice).sort((a, b) => b - a);
  const averagePrice = getAverageValue([...maxPrices, ...minPrices]);
  if (priceRange.min > averagePrice * 0.7) {
    minPrice = priceRange.min;
  } else {
    minPrice = minPrices.find(item => item > averagePrice * 0.7);
  }
  if (priceRange.max < averagePrice * 1.3) {
    maxPrice = priceRange.max;
  } else {
    maxPrice = maxPrices.find(item => item < averagePrice * 1.3);
  }

  return {
    minPrice,
    maxPrice,
  };
}

const Range = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number,
});

const TrimPartsInfo = PropTypes.shape({
  cfg: PropTypes.arrayOf(
    PropTypes.shape({
      formattedName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
});

const TrimStyleInfo = PropTypes.shape({
  bodyType: PropTypes.string,
  trim: PropTypes.string,
  trimSlug: PropTypes.string,
  year: PropTypes.number,
  styleId: PropTypes.string,
  model: PropTypes.string,
  make: PropTypes.string,
});

const TrimPrice = PropTypes.shape({
  displayPrice: PropTypes.number,
  guaranteedPrice: PropTypes.number,
  loan: PropTypes.shape({
    payment: PropTypes.number,
  }),
});

const TrimAggregateInfo = PropTypes.shape({
  inventoryCount: PropTypes.number,
  dealerCount: PropTypes.number,
  colorExteriorGenericNameCount: PropTypes.number,
  highlightedCgfs: PropTypes.arrayOf(
    PropTypes.shape({
      formattedName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
});

const TrimInfo = PropTypes.shape({
  prices: TrimPrice,
  trimAggregateInfo: TrimAggregateInfo,
  vehicleInfo: PropTypes.shape({
    styleInfo: TrimStyleInfo,
    partsInfo: TrimPartsInfo,
  }),
  vin: PropTypes.string,
});

const TrimsInfo = PropTypes.shape({
  inventories: PropTypes.shape({
    totalNumber: PropTypes.number,
    totalPages: PropTypes.number,
    results: PropTypes.arrayOf(TrimInfo),
  }),
});

const TrimYearsBodyTypes = PropTypes.shape({
  bodyTypes: PropTypes.objectOf(
    PropTypes.shape({
      count: PropTypes.number,
      bodyType: PropTypes.objectOf(PropTypes.number),
    })
  ),
});

const MakeProps = PropTypes.shape({
  name: PropTypes.string,
  niceName: PropTypes.string,
});

const ModelProps = PropTypes.shape({
  name: PropTypes.string,
  niceName: PropTypes.string,
});

const TrimsFilter = PropTypes.shape({
  make: MakeProps,
  model: ModelProps,
  modelYear: PropTypes.shape({
    year: PropTypes.number,
  }),
  zipCode: PropTypes.string,
  radius: PropTypes.number,
  bodyType: PropTypes.string,
});

const TrimCardFeatures = PropTypes.arrayOf(PropTypes.string);

const MMYSubmodelTypeAmountsTrim = PropTypes.shape({
  maxMileage: PropTypes.number,
  maxPrice: PropTypes.number,
  minMileage: PropTypes.number,
  minPrice: PropTypes.number,
  inventoryCount: PropTypes.number,
  name: PropTypes.string,
});

const MMYSubmodelTypeAmounts = PropTypes.shape({
  inventoryCount: PropTypes.number,
  minRating: PropTypes.number,
  maxRating: PropTypes.number,
  minPrice: PropTypes.number,
  maxSavings: PropTypes.number,
  minMileage: PropTypes.number,
  trims: PropTypes.arrayOf(MMYSubmodelTypeAmountsTrim),
  priceRanges: PropTypes.objectOf(Range),
});

const MMYSubmodelColorObj = {
  count: PropTypes.number,
  name: PropTypes.string,
  properties: PropTypes.shape({
    rgb: PropTypes.string,
  }),
};

const MMYSubmodelColor = PropTypes.shape(MMYSubmodelColorObj);

const MMYSubmodelColors = PropTypes.arrayOf(MMYSubmodelColor);

const MMYSubmodelFeatureObj = {
  count: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
};

const MMYSubmodelFeature = PropTypes.shape(MMYSubmodelFeatureObj);

const MMYSubmodelFeatures = PropTypes.arrayOf(MMYSubmodelFeature);

const MMYSubmodelFeaturesColors = PropTypes.shape({
  colors: MMYSubmodelColors,
  features: MMYSubmodelFeatures,
});

const StyleIdTrimInventoryCount = PropTypes.shape({
  trim: PropTypes.string,
  inventoryCount: PropTypes.number,
});

const TrimInfoInventory = PropTypes.shape({
  trim: PropTypes.string,
  count: PropTypes.number,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  styleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const TrimInfoInventories = PropTypes.arrayOf(TrimInfoInventory);

export const TrimsEntities = {
  TrimsInfo,
  TrimStyleInfo,
  TrimInfo,
  TrimPrice,
  TrimPartsInfo,
  TrimAggregateInfo,
  TrimYearsBodyTypes,
  TrimsFilter,
  TrimCardFeatures,
};

export const InventoryAggregateEntities = {
  MMYSubmodelTypeAmounts,
  MMYSubmodelTypeAmountsTrim,
  MMYSubmodelFeaturesColors,
  MMYSubmodelFeatures,
  MMYSubmodelColors,
  MMYSubmodelColorObj,
  MMYSubmodelFeatureObj,
  StyleIdTrimInventoryCount,
  TrimInfoInventory,
  TrimInfoInventories,
  Range,
};

export const GreatDeals = PropTypes.shape({
  count: PropTypes.number,
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  styleId: PropTypes.string,
});

/**
 * Model segment dedicated for inventory aggregate queries like /inventory/v{\d+}/aggregate/?by=...
 */
export const InventoryAggregateModel = createModelSegment('inventoryAggregate', [
  {
    path: 'yearsBodyTypesFilter',
    async resolve(match, context) {
      const vehicle = await context.resolveValue('vehicle', PageModel);
      if (!vehicle) return undefined;

      const {
        make,
        model,
        modelYear: { year },
      } = vehicle;
      const { zipCode, dmaRank } = await context.resolveValue('location', VisitorModel);
      const radius = getRadiusByDmaRank(dmaRank);

      return {
        make,
        model,
        modelYear: { year: Number(year) },
        zipCode,
        radius,
      };
    },
    async update(filtersData, match, context) {
      const {
        make,
        model,
        modelYear: { year },
      } = await context.resolveValue('vehicle', PageModel);
      const { zipCode, dmaRank } = await context.resolveValue('location', VisitorModel);
      const radius = getRadiusByDmaRank(dmaRank);

      return {
        make,
        model,
        modelYear: { year: Number(year) },
        zipCode,
        radius,
        ...filtersData,
      };
    },
  },
  /**
   * @see buildMMYSubmodelTypeAmountsPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].types["{type}"].amounts',
    async resolve({ make, model, year, submodel, type }, context) {
      const { zipCode, dma, latitude, longitude } = await context.resolveValue('location', VisitorModel);

      let queryParams = `?zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}&make=${make}&model=${model}&year=${year}&type=${type}&deliveryType=all&by=vehicleInfo.styleInfo.trim,vehicleInfo.styleInfo.make,dealerInfo.displayInfo.salesOverallRating,vehicleInfo.styleInfo.subModels.niceId,prices.displayPriceDelta,prices.guaranteedPriceDelta`;
      if (submodel !== DEFAULT_SUBMODEL) {
        queryParams = `${queryParams}&submodel=${submodel}`;
      }

      // We perform 2 calls - 1st for inventory counts (with 0-priced vehicles), 2nd for min-max prices (without 0-priced vehicles)
      const [responseWithoutPrices, responseWithPrices] = await Promise.all([
        withMetrics(EdmundsAPI, context)
          .fetchJson(
            `${INVENTORY_AGGREGATE_API_PREFIX}${queryParams}&byminmax=vehicleInfo.mileage,prices.savings,vehicleInfo.styleInfo.trim:vehicleInfo.mileage`
          )
          .catch(() => ({})),
        withMetrics(EdmundsAPI, context)
          .fetchJson(
            `${INVENTORY_AGGREGATE_API_PREFIX}${queryParams}&-prices.advertisedPrice=0&byminmax=prices.advertisedPrice,vehicleInfo.styleInfo.trim:prices.advertisedPrice,vehicleInfo.styleInfo.subModels.niceId:prices.advertisedPrice`
          )
          .catch(() => ({})),
      ]);

      const response = merge({}, responseWithPrices, {
        ...responseWithoutPrices,
        'vehicleInfo.styleInfo.subModels.niceId': undefined, // to not override a correct response
      });

      const ratings = getMinMaxRatings(get(responseWithPrices, 'dealerInfo.displayInfo.salesOverallRating', {}));
      const trims = transformTrims(get(response, 'vehicleInfo.styleInfo.trim', {}));
      const priceRanges = getPriceRanges(get(response, 'vehicleInfo.styleInfo.subModels.niceId', {}));
      const priceDeltas = {
        guaranteedPriceDelta: get(response, 'prices.guaranteedPriceDelta', {}),
        displayPriceDelta: get(response, 'prices.displayPriceDelta', {}),
      };

      return {
        maxSavings: getFiniteValue(response, 'max_prices.savings'),
        inventoryCount: takeFirstObjectValue(get(response, 'vehicleInfo.styleInfo.make')),
        minPrice: getFiniteValue(response, 'min_prices.advertisedPrice'),
        minMileage: getFiniteValue(response, 'min_vehicleInfo.mileage'),
        ...ratings,
        trims,
        priceRanges,
        priceDeltas,
      };
    },
  },
  {
    path: 'greatDeals.type.{type}.top.{top}',
    resolve({ type, top }, context) {
      const EMPTY_POPULAR_SEARCHES = [{ dataLoaded: false }];
      const provider = 'OEM';
      const shotType = 'F34,FQ,FQN';
      const imageWidth = '300';
      return context.resolveValue('location', VisitorModel).then(({ zipCode, dma, latitude, longitude }) =>
        withMetrics(EdmundsAPI, context)
          .fetchJson(
            `${INVENTORY_AGGREGATE_API_PREFIX}?zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}&radius=100&thirdPartyInfo.priceValidation.dealType=Great&type=${type}&by=vehicleInfo.styleInfo.model:vehicleInfo.styleInfo.make:vehicleInfo.styleInfo.styleId&top=${top}`
          )
          .then(response => transformGreatDeals(response))
          .then(greatDeals => fetchImages(greatDeals, context, provider, shotType, imageWidth))
          .catch(() => EMPTY_POPULAR_SEARCHES)
      );
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/inventory/v5/aggregate?make=honda&model=civic&type=NEW&radius=200&zip=90404&by=vehicleInfo.styleInfo.model
   * @see buildMakeModelInventoryCountPath
   */
  {
    path: 'makes["{make}"].models["{model}"].types["{types}"].radius["{radius}"].inventoryCount',
    async resolve({ make, model, types, radius }, context) {
      const { zipCode, dma, latitude, longitude } = await context.resolveValue('location', VisitorModel);
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `${INVENTORY_AGGREGATE_API_PREFIX}?make=${make}&model=${model}&type=${types}&by=vehicleInfo.styleInfo.model&radius=${radius}&zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}`
        )
        .then(data => {
          const modelName = Object.keys(get(data, 'vehicleInfo.styleInfo.model', {}))[0];

          return get(data, `["vehicleInfo.styleInfo.model"]["${modelName}"]`, 0);
        });
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/gateway/api/inventory/v5/aggregate?by=vehicleInfo.styleInfo.trim&searchable=true&make=toyota&model=rav4&year=2020&zip=90404&lat=34.028496&lon=-118.470151&userDma=803&radius=25&vehicleInfo.styleInfo.styleId
   * note that the param `vehicleInfo.styleInfo.styleId` is added so that we only include vehicles with style ids,
   * otherwise we cannot make the leads-targets call in the pricing drawer
   *
   * @see buildTrimsInventoryPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].trims',
    async resolve({ make, model, year }, context) {
      const { zipCode, dma, dmaRank, latitude, longitude } = await context.resolveValue('location', VisitorModel);
      const radius = getRadiusByDmaRank(dmaRank);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `${INVENTORY_AGGREGATE_API_PREFIX}?by=vehicleInfo.styleInfo.trim&searchable=true&make=${make}&model=${model}&year=${year}&zip=${zipCode}&lat=${latitude}&lon=${longitude}&userDma=${dma}&radius=${radius}&vehicleInfo.styleInfo.styleId`
        )
        .then(result => get(result, 'vehicleInfo.styleInfo.trim', {}));
    },
  },
]);

export const getFromModelState = path =>
  compose(
    modelState => modelState.get(path, InventoryAggregateModel),
    getModelState
  );

export function buildMMYSubmodelTypeAmountsPath({ make, model, year, submodel = DEFAULT_SUBMODEL, isUsed }) {
  const type = isUsed ? `${INVENTORY_TYPES.USED},${INVENTORY_TYPES.CPO}` : INVENTORY_TYPES.NEW;

  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].types["${type}"].amounts`;
}

export function buildMakeModelInventoryCountPath({ make, model, types = INVENTORY_TYPES.NEW, radius = 200 }) {
  return `makes["${make}"].models["${model}"].types["${types}"].radius["${radius}"].inventoryCount`;
}

export function buildTrimsInventoryPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].trims`;
}
